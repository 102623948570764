<template>
  <el-dialog
    :close-on-click-modal="false"
    append-to-body
    v-elDragDialog
    :title="$t('publishApp.selectDevice')"
    :visible.sync="show"
    width="850px"
    v-loading="loading"
  >
    <div class="form">
      <el-input
        type="textarea"
        v-model="sn"
        :placeholder="$t('publishApp.SNTips')"
        rows="4"
        resize="none"
      />
      <el-button size="small" type="primary" style="margin-top:10px" @click="addHandler"
        >{{$t('publishApp.add')}}</el-button
      >
    </div>

    <div class="header" style="margin-top:15px">
      <div class="left">{{$t('publishApp.addedSN')}}：{{$t('publishApp.common')}} {{ list.length }} {{$t('publishApp.ge')}}</div>
      <div class="right">
        <el-link type="danger" :underline="false" icon="el-icon-delete" @click="clear"
          >{{$t('publishApp.clearAll')}}</el-link
        >
      </div>
    </div>

    <div class="content">
      <template v-if="list.length">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="item-left">
            <span class="name">{{ item.sn }}</span>
          </div>
          <div class="item-right" @click="list.splice(index, 1)">
            x
          </div>
        </div>
      </template>
      <p class="no-data" v-else>{{$t('noData')}}</p>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{$t('cancel')}}</el-button>
      <el-button type="primary" @click="submit">{{$t('confirm')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['selectData'],
  components: {},
  data() {
    return {
      sn: '',
      list: []
    }
  },
  created() {
    if (this.selectData.length) {
      this.list = [...this.selectData]
      this.init()
    }
  },
  methods: {
    init() {
      this.list = [...this.selectData]
    },
    async addHandler() {
      try {
        if (!this.sn) {
          return this.$message.warning(this.$t('publishApp.SNTips'))
        }
        this.loading = true
        const res = await this.$api.deviceManagement.getDeviceName({ snList: this.sn.split(',') })

        this.list = res.result.map(item => {
          item.deviceTotal = res.result.length
          return item
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    clear() {
      this.list = []
    },
    submit() {
      if (!this.list.length) {
        return this.$message.warning(this.$t('publishApp.enterSNNumbe'))
      }
      this.$emit('update:selectData', this.list)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
}
.content {
  margin-top: 10px;
  max-height: 320px;
  min-height: 200px;
  overflow: auto;
  position: relative;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  .item {
    display: inline-block;
    margin-right: 12px;
    margin-top: 10px;
    width: 220px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #f2f2f2;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    .item-left {
      float: left;
      display: flex;
      flex-direction: column;
      line-height: 18px;
      .name {
        font-size: 13px;
      }
      .num {
        font-size: 12px;
        color: #ccc;
      }
    }
    .item-right {
      font-size: 20px;
      float: right;
      margin-top: 10px;
      cursor: pointer;
      width: 0;
      height: 0;
      border-top: 20px solid #f56c6c;
      border-left: 20px solid transparent;
      position: absolute;
      right: 0;
      margin: 0;
      top: 0;
      &::after {
        content: 'x';
        position: absolute;
        left: -10px;
        font-size: 12px;
        color: #fff;
        top: -25px;
      }
    }
  }
}
</style>
